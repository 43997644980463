import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"

import Button from "../components/button"
import Cartouche from "../components/cartouche"
import Layout from "../components/layout"
import ProjectTeaser from "../components/ProjectTeaser"
import Seo from "../components/seo"

const ProjetsPage = () => {
  const [state, setState] = useState({
    filterByCategory: undefined
  });

  const data = useStaticQuery(graphql`
    query getPageProject {
      wpPage(slug: {eq: "projets"}) {
        id
        title
        content
        slug
        featuredImage {
          node {
            imageCustomFields {
              position
            }
            localFile {
              publicURL
            }
          }
        }
      }

      allWpProjectCategory(filter: {count: {gt: 0}}) {
        categories: edges {
          node {
            id
            name
            slug
          }
        }
      }

      allWpProject(sort: {fields: date, order: DESC}) {
        projects: nodes {
          id
          title
          slug
          uri
          projectDetails {
            year
            localisation
          }
          projectCategories {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              imageCustomFields {
                position
              }
              altText
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!data) return null

  const {
    title,
    content,
    featuredImage
  } = data.wpPage

  const {
    projects,
  } = data.allWpProject

  const {
    categories,
  } = data.allWpProjectCategory

  const filterProjects = (e, categorySlug) => {
    if (state.filterByCategory === categorySlug) {
      setState({ filterByCategory: undefined })
    } else {
      setState({ filterByCategory: categorySlug })
    }
    e.preventDefault();
  }

  return (
    <Layout featuredImage={featuredImage}>
      <Seo title={title} />
      <div className="container">
        <div className="row">
          <div className="column-left">
            <div className="sticky-top">
              <h1>{title}</h1>
              <div className="categories-filter">
                {
                  categories.map(function (category) {
                    return (
                      <Button
                        link={`#${category.node.slug}`}
                        key={category.node.id}
                        color={state.filterByCategory === category.node.slug ? 'black' : 'grey'}
                        onClick={(e) => { filterProjects(e, category.node.slug) }}
                      >
                        {category.node.name}
                      </Button>
                    );
                  })
                }
              </div>
            </div>

          </div>
          <div className="column-right mt-negative">
            {
              content && (
                <Cartouche>
                  <h1>{title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: content }}></div>
                </Cartouche>
              )
            }
            {
              projects.map(function (project) {
                return (
                  <ProjectTeaser
                    key={project.id}
                    project={project}
                    isOpened={
                      project.projectCategories.nodes.length === 0 & state.filterByCategory === undefined ? true :
                        project.projectCategories.nodes.some(
                          (category) => {
                            if (state.filterByCategory) {
                              return category.slug === state.filterByCategory;
                            } else {
                              return category;
                            }
                          }
                        )
                    }
                  ></ProjectTeaser>
                )
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProjetsPage;
