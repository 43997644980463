import React from "react"
import {Link} from "gatsby"
import { Collapse } from 'react-collapse';

import * as styles from './ProjectTeaser.module.scss'
import placeholder from '../../images/placeholder.jpg';

const ProjectTeaser = ({ project, isOpened }) => {
  let featuredImage = project.featuredImage
    ? (
      <img src={project.featuredImage.node.mediaDetails.sizes.filter(size => size.name === 'thumbnail')[0].sourceUrl} alt={project.featuredImage.altText} />
    )
    : < img src={placeholder} alt="" />

  return (
    <Collapse isOpened={isOpened} theme={{ collapse: styles.collapse, content: styles.collapseContent }}>
      <Link to={project.uri} className={styles.projectTeaser}>
        <div className={ styles.textContainer }>
          <h2 className={ styles.title }>
            { project.title }
          </h2>
          <div className={ styles.infos }>
            <div>
              <span className="text-muted">
                {project.projectDetails.year}<br />
              </span>
              {project.projectDetails.localisation}
            </div>
            <span className={ styles.more }>+</span>
          </div>
        </div>
        <div className={ styles.thumbnailContainer }>
          {featuredImage}
        </div>
    </Link>
    </Collapse>
  )
}

export default ProjectTeaser
